import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Gymnast, GymnastRowEditMode, GymnastRowReadMode} from "../models/Gymnast";
import {Category, TotalCalculationType} from "../models/Category";
import {
    addGymnast,
    deleteGymnast,
    getAllGymnastsInCategoryInCompetition,
    getCategory,
    updateGymnast
} from "../util/firebase";
import {UserContext} from "../util/auth";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Result} from "../models/Result";
import './ResultsList.css';
import {ExpandLess} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import strings from "../res/strings";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl";

export interface ResultsCallback {
    (results: Gymnast[]): void
}

export interface CategoryCallback {
    (category: Category): void
}

export const ResultList = (): ReactElement => {
    const user = useContext(UserContext);

    return <div className="PaddedPage TextColored">
        {user ?
            <EditModeTable/> :
            <ReadOnlyModeTable/>}
    </div>

};

const EditModeTable = () => {
    const [adding, setAdding] = useState<boolean>(false);
    const [newGymnast, setNewGymnast] = useState<Gymnast>({});
    const [rows, setRows] = useState<GymnastRowEditMode[]>([]);
    const {competitionId, categoryId} = useParams();
    const [category, setCategory] = useState<Category>({});
    const saveButtonEnabled = newGymnast.startNumber && newGymnast.name && newGymnast.club;
    const history = useHistory();

    const setResults = (results: Gymnast[]) => {
        const competing: Gymnast[] = [];
        const notCompeting: Gymnast[] = [];
        results.forEach(g => {
            g.notCompeting ? notCompeting.push(g) : competing.push(g)
        });
        let sortedResults = competing.sort((a, b) => (a.startNumber || "0").localeCompare(b.startNumber || "0", undefined, {
            numeric: true,
            sensitivity: 'base'
        }));
        sortedResults.push(...notCompeting);
        setRows(sortedResults.map(r => {
            return {isEditMode: false, gymnast: r}
        }));
    };

    useEffect(() => {
        return getAllGymnastsInCategoryInCompetition(competitionId, categoryId, setResults);
    }, [categoryId, competitionId]);

    useEffect(() => {
        return getCategory(competitionId, categoryId, setCategory);
    }, [categoryId, competitionId]);

    let addGymnastBlock = <div/>;
    if (adding) {
        addGymnastBlock =
            <FormControl style={{display: "inline-block"}}>
                <TextField id="outlined-basic" label={strings.startNumber} variant="outlined" size="small"
                           autoComplete="off"
                           onChange={event => {
                               setNewGymnast({...newGymnast, startNumber: event.target.value})
                           }}/>
                <TextField id="outlined-basic" label={strings.gymnastName} variant="outlined" size="small"
                           autoComplete="off"
                           onChange={event => {
                               setNewGymnast({...newGymnast, name: event.target.value})
                           }}/>
                <TextField id="outlined-basic" label={strings.clubName} variant="outlined" size="small"
                           autoComplete="off"
                           onChange={event => {
                               setNewGymnast({...newGymnast, club: event.target.value})
                           }}/>
                <Button variant="outlined" size="small" className="NewSaveCancelButton"
                        disabled={!saveButtonEnabled} onClick={() => {
                    setAdding(false);
                    setNewGymnast({});
                    addGymnast(competitionId, categoryId, category.apparatus || [], newGymnast);
                }}>{strings.editing.save}
                </Button>
                <Button variant="outlined" size="small" className="NewSaveCancelButton"
                        onClick={() => {
                            setNewGymnast({});
                            setAdding(false);
                        }}>{strings.editing.cancel}
                </Button>
            </FormControl>
    } else {
        addGymnastBlock = <Button variant="outlined" onClick={() => setAdding(true)} className="NewSaveCancelButton"
                                  startIcon={<AddCircleOutlineIcon/>}>{strings.addGymnast}</Button>
    }

    const onToggleEditMode = (id: string) => {
        setRows(() => {
            return rows.map(row => {
                if (row.gymnast.id === id) {
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };

    return <div>
        <div style={{display: "flex", alignItems: "center"}}>
            <Tooltip title={strings.backToCategories}>
                <IconButton
                    aria-label="back-to-categories"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <ArrowBackIcon/>
                </IconButton>
            </Tooltip>
            <h1>{category.name}</h1>
        </div>
        {addGymnastBlock}
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow className="HeaderRow">
                        <TableCell align="left"/>
                        <TableCell component="th" scope="col"
                                   className="HeaderRowCell">{strings.startNumber}</TableCell>
                        <TableCell component="th" scope="col"
                                   className="HeaderRowCell">{strings.gymnastName}</TableCell>
                        <TableCell component="th" scope="col" className="HeaderRowCell">{strings.clubName}</TableCell>
                        {(category.apparatus || []).map(a =>
                            <TableCell component="th" scope="col" className="HeaderRowCell"
                                       key={a}>{a}</TableCell>)}
                        <TableCell component="th" scope="col" className="HeaderRowCell">{strings.total}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row) => (
                        <TableRow key={row.gymnast.name}
                                  className={row.gymnast.notCompeting ? "LineThroughRow" : ""}>
                            <TableCell>
                                {row.isEditMode ? (
                                    <>
                                        <Tooltip title={strings.editing.saveChanges}>
                                            <IconButton
                                                aria-label="done"
                                                onClick={() => {
                                                    onToggleEditMode((row.gymnast.id || ""));
                                                    updateGymnast(competitionId, categoryId, category, row.gymnast);
                                                }}
                                            >
                                                <DoneIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Tooltip title={strings.editing.edit}>
                                            <IconButton disabled={row.gymnast.notCompeting}
                                                        aria-label="edit"
                                                        onClick={() => {
                                                            onToggleEditMode((row.gymnast.id || ""))
                                                        }}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={strings.gymnastNotCompeting}>
                                            <IconButton
                                                aria-label="mark-as-not-competing"
                                                onClick={() => {
                                                    updateGymnast(competitionId, categoryId, category, {
                                                        ...row.gymnast,
                                                        notCompeting: !row.gymnast.notCompeting
                                                    })
                                                }}
                                            >
                                                <RemoveIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={strings.editing.delete}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    const confirmed = window.confirm(strings.confirmGymnastDelete);
                                                    if (confirmed) {
                                                        deleteGymnast(competitionId, categoryId, row.gymnast.id || "")
                                                    }
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </TableCell>
                            <TableCell className="BodyRowCell">
                                {row.gymnast.startNumber}
                            </TableCell>
                            <TableCell className="BodyRowCell">
                                {row.gymnast.name}
                            </TableCell>
                            <TableCell className="BodyRowCell">
                                {row.gymnast.club}
                            </TableCell>
                            {(category.apparatus || []).map(a => {
                                //TODO: find more optimal solution
                                const result: Result | null = (row.gymnast.results && row.gymnast.results.find(r => r.apparatus === a)) || null;
                                const scoreValue = (result && result.score) || "";
                                return row.isEditMode ? (
                                    <TableCell className="BodyRowCell" key={row.gymnast.id + a + "edit"}>
                                        <TextField variant="outlined" size="small"
                                                   autoComplete="off"
                                                   type='number'
                                                   value={scoreValue}
                                                   onChange={e => {
                                                       const newRows: GymnastRowEditMode[] = rows.map(r => {
                                                           if (r.gymnast.id === row.gymnast.id) {
                                                               (r.gymnast.results || []).map((result) => {
                                                                   if (result.apparatus === a) {
                                                                       result.score = parseFloat(e.target.value);
                                                                   }
                                                                   return result.score;
                                                               });
                                                               return {
                                                                   isEditMode: r.isEditMode,
                                                                   gymnast: {...r.gymnast}
                                                               };
                                                           }
                                                           return r;
                                                       });
                                                       setRows(newRows);
                                                   }}
                                                   onKeyPress={e => {
                                                       if (e.key === 'Enter') {
                                                           onToggleEditMode((row.gymnast.id || ""));
                                                           updateGymnast(competitionId, categoryId, category, row.gymnast);
                                                       }
                                                   }}
                                        />
                                    </TableCell>) : (
                                    <TableCell key={row.gymnast.id + a}
                                               className={counting(row.gymnast.results, result, category.totalConfig) ? "CountingScore BodyRowCell" : "BodyRowCell"}>{scoreValue}</TableCell>);
                            })}
                            <TableCell className="BodyRowCell" scope="total">
                                {row.gymnast.total}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>;

};


const ReadOnlyModeTable = () => {
    const {competitionId, categoryId} = useParams();
    const [results, setResults] = useState<GymnastRowReadMode[]>([]);
    const [category, setCategory] = useState<Category>({});

    const setResultsSorted = (results: Gymnast[]) => {
        const sortedResults = results.sort((a, b) => (b.total || 0) - (a.total || 0));
        setResults(sortedResults.map(r => {
            return {gymnast: r, isOpen: false};
        }));
    };

    useEffect(() => {
        return getAllGymnastsInCategoryInCompetition(competitionId, categoryId, setResultsSorted);
    }, [categoryId, competitionId]);

    useEffect(() => {
        return getCategory(competitionId, categoryId, setCategory);
    }, [categoryId, competitionId]);

    const onToggleOpen = (id: string) => {
        setResults(() => {
            return results.map(row => {
                if (row.gymnast.id === id) {
                    return {...row, isOpen: !row.isOpen};
                }
                return row;
            });
        });
    };

    const getPlaceByApp = (result: Result | null, results: Gymnast[]) => {
        if (!result || !result.score) {
            return null;
        }
        const apparatusResults = results.flatMap(v => v.results).filter((r) => {
            return r && r.apparatus === result.apparatus
        }).map(r => r && r.score).sort((a, b) => (b || 0) - (a || 0));
        return apparatusResults.indexOf(result.score) + 1;
    };

    const getPlace = (total: number | null | undefined) => {
        const totals = results.map(g => (g && g.gymnast.total) || 0).sort((a, b) => b - a);
        console.log(totals);
        return totals.indexOf(total || 0) + 1;
    };

    return <div>
        <h1>{category.name}</h1>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow className="HeaderRow">
                        <TableCell component="th" scope="row" className="HeaderRowCell">{strings.place}</TableCell>
                        <TableCell component="th" scope="row"
                                   className="HeaderRowCell">{strings.gymnastName}</TableCell>
                        <TableCell component="th" scope="row" className="HeaderRowCell">{strings.clubName}</TableCell>
                        {(category.apparatus || []).map(a =>
                            <TableCell component="th" scope="row" className="HeaderRowCell ApparatusResultCell"
                                       key={a}>{a}</TableCell>)}
                        <TableCell component="th" scope="row" className="HeaderRowCell">{strings.total}</TableCell>
                        <TableCell className="ExpandCell"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((row, index) => (
                        <>
                            <TableRow key={row.gymnast.name}
                                      className={row.gymnast.notCompeting ? "LineThroughRow" : ""}>
                                <TableCell className="BodyRowCell">
                                    {getPlace(row.gymnast.total)}
                                </TableCell>
                                <TableCell className="BodyRowCell">
                                    {row.gymnast.name}
                                </TableCell>
                                <TableCell className="BodyRowCell">
                                    {row.gymnast.club}
                                </TableCell>
                                {(category.apparatus || []).map(a => {
                                    //TODO: find more optimal solution
                                    const result: Result | null = (row.gymnast.results && row.gymnast.results.find(r => r.apparatus === a)) || null;
                                    const scoreValue = (result && result.score) || "";
                                    const placeByApp = getPlaceByApp(result, results.map(v => v.gymnast));
                                    const placeByAppValue = placeByApp ? "(" + placeByApp + ")" : "";
                                    return <TableCell key={row.gymnast.id + a}
                                                      className={counting(row.gymnast.results, result, category.totalConfig) ? "ApparatusResultCell CountingScore BodyRowCell" : "ApparatusResultCell BodyRowCell"}
                                    >{scoreValue} {placeByAppValue}</TableCell>;
                                })}
                                <TableCell className="BodyRowCell" scope="total">
                                    {row.gymnast.total}
                                </TableCell>
                                <TableCell className="ExpandCell">
                                    {!row.gymnast.notCompeting ?
                                        <IconButton aria-label="expand row" size="small" onClick={() => {
                                            onToggleOpen(row.gymnast.id || "")
                                        }}>
                                            {!row.isOpen ? <ExpandMore/> : <ExpandLess/>}
                                        </IconButton> : <></>}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6} className="ExpandCell">
                                    <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            {(row.gymnast.results || []).map(r => {
                                                const placeByApp = getPlaceByApp(r, results.map(v => v.gymnast));
                                                const placeByAppValue = placeByApp ? "(" + placeByApp + ")" : "";
                                                return r.score ? <div key={row.gymnast.id + r.apparatus}
                                                                      className={counting(row.gymnast.results, r, category.totalConfig) ? "CountingScore" : ""}>
                                                    <b>{r.apparatus}</b>: {r.score} {placeByAppValue}</div> : <></>
                                            })}
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>;

};

function counting(scores: Result[] | undefined, score: Result | null, totalConfig: TotalCalculationType | null | undefined): boolean {
    if (!score) return false;
    switch (totalConfig) {
        case TotalCalculationType.All:
            return false;
        case TotalCalculationType.TwoBest:
            if (!scores || scores.length <= 2) {
                return true;
            }
            const sortedResults = scores.sort((a, b) => (b.score || 0) - (a.score || 0));
            return sortedResults[0] === score || sortedResults[1] === score;
        case TotalCalculationType.OneBest:
            if (!scores || scores.length <= 1) {
                return true;
            }
            return Math.max(...scores.map(s => s.score || 0)) === score.score;
        case TotalCalculationType.FreehandsPlusTwo:
            if (!scores || scores.length <= 3 || score.apparatus === "Frittstående") {  //TODO ikke hardkod
                return true;
            }
            const withoutFreehands = scores.filter(r => r.apparatus !== "Frittstående");
            const sorted = withoutFreehands.sort((a, b) => (b.score || 0) - (a.score || 0));
            return sorted[0] === score || sorted[1] === score;
    }
    return false;
}