import firebase from 'firebase/app';
import "firebase/firestore";
import {Category} from "./Category";

export type Competition = {
    id: string,
    name: string;
    fromDate?: string;
    toDate?: string;
    published?: boolean;
    categories?: Category[]
}

export type CompetitionRow = {
    competition: Competition,
    isEditMode: boolean
}

export function competitionFromSnapshot(doc: firebase.firestore.DocumentSnapshot, categories?: Category[]){
    return {
        id: doc.id,
        name: doc.get("name"),
        fromDate: doc.get("fromDate"),
        toDate: doc.get("toDate"),
        published: doc.get("published"),
        categories: categories || []
    }
}