import React, {useContext, useState} from "react";
import {login, UserContext} from "../util/auth";
import {Redirect} from "react-router-dom";
import '../index.css';
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import strings from "../res/strings";
import Button from "@material-ui/core/Button";

export function LoginView() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const user = useContext(UserContext);


    return (user) ? <Redirect to={"/"}/> : (
        <div style={{display:"flex", justifyContent:"center", padding:"5px"}}>
            <FormControl>
                <TextField style={{margin: "5px"}} id="emailInput" label={strings.userName} variant="outlined"
                           size="small" autoComplete="off"
                           onChange={event => {
                               setUsername(event.target.value);
                           }}
                />
                <TextField style={{margin: "5px"}} id="passwordInput" label={strings.password} variant="outlined"
                           size="small" autoComplete="off"
                           type="password"
                           onChange={event => {
                               setPassword(event.target.value);
                           }}
                />
                <Button style={{margin: "5px"}} variant="contained" size="small" className="NewSaveCancelButton"
                        onClick={async () => {
                            const error = await login(username, password);
                            if (error) {
                                setError(error)
                            }
                        }}
                >
                    {strings.logIn}
                </Button>
                {error ? <div className="ErrorMessage">{error}</div> : <></>}
            </FormControl>
        </div>
    );
}