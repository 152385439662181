import firebase from './firebaseConfiguration';
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import {Competition, competitionFromSnapshot} from "../models/Competition";
import {CompetitionsCallback} from "../competitionList/CompetitionList";
import {Category, categoryFromSnapshot, TotalCalculationType} from "../models/Category";
import {CategoriesCallback, CompNameCallback} from "../categoryList/CategoryList";
import {Gymnast, gymnastFromSnapshot} from "../models/Gymnast";
import {CategoryCallback, ResultsCallback} from "../resultList/ResultList";
import {StringCallback} from "../Toolbar";

const databaseRef = firebase.firestore();
export const competitionsRef = databaseRef.collection("competitions");
export const categoriesRef = databaseRef.collection("categories");
export const usersRef = databaseRef.collection("users");

//export default firebase;

export function getAllCompetitions(callback: CompetitionsCallback) {
    competitionsRef.where("published", "==", true)
        .onSnapshot(snapshot => {
            let competitions: Competition[] = [];
            snapshot.docs.map((doc => {
                competitions.push(competitionFromSnapshot(doc));
                return doc;
            }));
            callback(competitions)
        });
}

export function getAllCategoriesInCompetition(competitionId: string, callback: CategoriesCallback) {
    competitionsRef.doc(competitionId).collection("categories")
        .onSnapshot(snapshot => {
            let categories: Category[] = [];
            snapshot.docs.map((doc => {
                categories.push(categoryFromSnapshot(doc));
                return doc;
            }));
            callback(categories);
        });
}

export function getCompetitionName(competitionId: string, callback: CompNameCallback) {
    competitionsRef.doc(competitionId)
        .onSnapshot(snapshot => {
            callback(snapshot.get("name"));
        });
}

export function getCategory(competitionId: string, categoryId: string, callback: CategoryCallback) {
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId)
        .onSnapshot(snapshot => {
            callback(categoryFromSnapshot(snapshot));
        });
}

export function getAllAvailableCategories(competitionId: string, callback: CategoriesCallback) {
    categoriesRef
        .onSnapshot(snapshot => {
            let categories: Category[] = [];
            snapshot.docs.map((doc => {
                categories.push(categoryFromSnapshot(doc));
                return doc;
            }));
            callback(categories);
        });
}

export function getAllGymnastsInCategoryInCompetition(competitionId: string, categoryId: string, callback: ResultsCallback) {
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId).collection("gymnasts")
        .onSnapshot(snapshot => {
            let gymnasts: Gymnast[] = [];
            snapshot.docs.map((doc => {
                gymnasts.push(gymnastFromSnapshot(doc));
                return doc;
            }));
            callback(gymnasts);
        });
}


export function getAllCompetitionsForUser(uid: string, callback: CompetitionsCallback) {
    usersRef.doc(uid)
        .onSnapshot(async snapshot => {
            let competitions: Competition[] = [];
            const usersCompetitions: firebase.firestore.DocumentReference[] = snapshot.get("competitions") || [];
            await Promise.all(usersCompetitions.map(ref => ref.get().then(doc => {
                competitions.push(competitionFromSnapshot(doc))
            })));
            callback(competitions)
        });
}

export function addCompetition(uid: string, compName: string) {
    if (compName !== "") {
        usersRef.doc(uid).get().then(snapshot => {
            let usersCompetitions: firebase.firestore.DocumentReference[] = snapshot.get("competitions") || [];
            competitionsRef.add({name: compName}).then(newCompetitionDoc => {
                usersCompetitions.push(newCompetitionDoc);
                usersRef.doc(uid).set({competitions: usersCompetitions});
            });
        });
    }
}

export function addCategoryToCompetition(competitionId: string, category: Category) {
    competitionsRef.doc(competitionId).collection("categories").add({...category});
}

export function addGymnast(competitionId: string, categoryId: string, apparatus: string[], gymnast: Gymnast) {
    let resultsSetup = {};
    apparatus.map(a => {
        resultsSetup = {...resultsSetup, [a]: null};
        return a;
    });
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId).collection("gymnasts").add({
        name: gymnast.name,
        startNumber: gymnast.startNumber,
        club: gymnast.club,
        results: resultsSetup,
        total: null,
        notCompeting: false
    });
}

export function updateGymnast(competitionId: string, categoryId: string, category: Category, gymnast: Gymnast) {
    let results = {};
    (gymnast.results || []).map(r => {
        results = {...results, [r.apparatus]: r.score};
        return r;
    });
    let total = calculateTotal(category, gymnast);
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId).collection("gymnasts").doc(gymnast.id).set({
        ...gymnast,
        results: results,
        total: total
    });
}

export function updateCompetitionName(competition: Competition) {
    competitionsRef.doc(competition.id).update({
        name: competition.name
    });
}

export function updateCompetitionVisibility(competition: Competition) {
    competitionsRef.doc(competition.id).update({
        published: competition.published
    });
}

export function deleteGymnast(competitionId: string, categoryId: string, gymnastId: string) {
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId).collection("gymnasts").doc(gymnastId).delete()
}

export function deleteCategory(competitionId: string, categoryId: string) {
    competitionsRef.doc(competitionId).collection("categories").doc(categoryId).delete()
}

export function deleteCompetition(userId: string, competitionId: string) {
    const docReference = competitionsRef.doc(competitionId);
    usersRef.doc(userId).get().then((snapshot: firebase.firestore.DocumentSnapshot) => {
        let usersCompetitions: firebase.firestore.DocumentReference[] = snapshot.get("competitions");
        usersCompetitions = usersCompetitions.filter(v => v.path !== docReference.path);
        docReference.delete().then(() => usersRef.doc(userId).set({competitions: usersCompetitions}).then());
    })
}

function calculateTotal(category: Category, gymnast: Gymnast): string | null {
    switch (category.totalConfig) {
        case TotalCalculationType.All: {
            const total = (gymnast.results || []).map(r => r.score).reduce((total, r) => (total || 0) + (r || 0), 0);
            return (total != null) ? total.toPrecision(5) : null;
        }
        case TotalCalculationType.TwoBest: {
            let scores = (gymnast.results || []).map(r => (r.score || 0));
            scores = scores.sort((a, b) => b - a);
            const total = scores[0] + scores[1];
            return (total != null) ? total.toPrecision(5) : null;
        }
        case TotalCalculationType.OneBest: {
            return Math.max(...(gymnast.results || []).map(s => s.score || 0)).toString()
        }
        case TotalCalculationType.FreehandsPlusTwo: {
            const freehandsRes = gymnast.results ? gymnast.results.find(r => r.apparatus === "Frittstående") : null;
            const freehandsScore = freehandsRes ? freehandsRes.score || 0 : 0;
            const withoutFreehands = gymnast.results ? gymnast.results.filter(r => r.apparatus !== "Frittstående") : [];
            let scores = (withoutFreehands || []).map(r => (r.score || 0));
            const sorted = scores.sort((a, b) => b - a);
            const total = freehandsScore + sorted[0] + sorted[1];
            return (total != null) ? total.toPrecision(5) : null;
        }
    }
    return null
}

export function getImage(callback: StringCallback) {
    firebase.storage().ref().child(`njaard-logo.jpg`).getDownloadURL().then((url) => {
        callback(url);
    })
}

