import firebase from 'firebase/app';

const devFirebaseConfig = {
    apiKey: "AIzaSyCd_qwWyAVx-i_HanwRTTDFtith-Agfl8E",
    authDomain: "resultatservicenjaard-dev.firebaseapp.com",
    databaseURL: "https://resultatservicenjaard-dev.firebaseio.com",
    projectId: "resultatservicenjaard-dev",
    storageBucket: "resultatservicenjaard-dev.appspot.com",
    messagingSenderId: "340242637268",
    appId: "1:340242637268:web:e2714c3acb5fe76883b641"
};

const prodFirebaseConfig = {
    apiKey: "AIzaSyBTwQwMdC5_udxZjEY8QG8ROVs_rsuL7U0",
    authDomain: "resultatservicenjaard.firebaseapp.com",
    databaseURL: "https://resultatservicenjaard.firebaseio.com",
    projectId: "resultatservicenjaard",
    storageBucket: "resultatservicenjaard.appspot.com",
    messagingSenderId: "1078566045565",
    appId: "1:1078566045565:web:7ddccfd778f06e0aefee14"
};

const firebaseConfig = process.env.NODE_ENV === 'production' ? prodFirebaseConfig : devFirebaseConfig;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase