import React, {useContext, useEffect, useState} from "react";
import {logout, UserContext} from "./util/auth";
import {Link} from "react-router-dom";
import './index.css';
import strings from "./res/strings";
import {getImage} from "./util/firebase";

export interface StringCallback {
    (results: string): void
}

export function Toolbar() {
    const user = useContext(UserContext);
    const [logo, setLogo] = useState<string>("");

    useEffect(() => {
        return getImage(setLogo);
    }, []);

    return <div className="Toolbar lgToolbar">
        <Link to={`/`} id="link">
            <img src={logo} alt={"Club logo"} className="Logo lgLogo"/>
        </Link>
        {user ? <div className="LogInOutButton lgLogInOutButton"
                     onClick={() => logout()}>{strings.logOut}</div> :
            <Link className="LogInOutButton lgLogInOutButton" to={"/login"}>{strings.logIn}</Link>}
    </div>

}