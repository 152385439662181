const strings = {
    competitions: "Konkurranser",
    myCompetitions: "Mine konkurranser",
    addCompetition: "Ny konkurranse",
    addCategory: "Ny klasse",
    addGymnast: "Ny gymnast",
    competitionName: "Konkurransenavn",
    editing: {
        save: "Lagre",
        saveChanges: "Lagre endringer",
        edit: "Rediger",
        delete: "Slett",
        cancel: "Avbryt"
    },
    confirmCompetitionDelete: "Er du sikker på at du ønsker å slette konkurransen med alle klasser og gymnaster i den?",
    confirmCategoryDelete: "Er du sikker på at du ønsker å slette klassen med alle gymnaster i den?",
    confirmGymnastDelete: "Er du sikker på at du ønsker å slette gymnasten?",
    chooseCategory: "Velg kategori",
    startNumber: "Startnummer",
    gymnastName: "Gymnast",
    clubName: "Klubb",
    total: "Sum",
    gymnastNotCompeting: "Stryk gymnasten",
    place: "Plass",
    userName: "Email",
    password: "Passord",
    logIn: "Logg inn",
    logOut: "Logg ut",
    publish: "Konkurransen er ikke synlig for publikum. Trykk for å publisere.",
    unpublish: "Konkurransen er synlig for publikum. Trykk for å skjule.",
    categoryName: "Skriv inn klassenavn",
    chooseTotalConfig: "Velg hvordan poengsummen regnes ut i denne klassen",
    chooseApparatus: "Velg hvilke apparater denne klassen kjører",
    choosePredefinedCategory: "Velg en av predefinerte klasser:",
    addOwnCategory: "Eller legg til egendefinert klasse:",
    totalConfigs: {
        ALL: "Alle karakterer teller",
        ONE: "Den beste karakteren teller",
        TWO: "De to beste karakterene teller",
        FreehandsPlusTwo: "Frittstående og to beste apparater teller",
        FhPlusOne: "Frittstående og det beste apparatet teller"
    },
    customApparatus: "Egendefinert redskap",
    backToCategories: "Tilbake til klasseoversikt",
    backToCompetitions: "Tilbake til konkurranseoversikt"
};

export default strings