import {Result} from "./Result";

export type Gymnast = {
    id?: string;
    startNumber?: string;
    name?: string;
    club?: string;
    results?: Result[];
    total?: number;
    notCompeting?: boolean;
}

export function gymnastFromSnapshot(doc: firebase.firestore.DocumentSnapshot) {
    let resultsMap: { [app: string]: number } = doc.get("results") || {};
    let results: Result[] = [];
    Object.keys(resultsMap).forEach(e => results.push({apparatus: e, score: resultsMap[e]}));
    return{
        id: doc.id,
        startNumber: doc.get("startNumber"),
        name: doc.get("name"),
        club: doc.get("club"),
        results: results,
        total: doc.get("total"),
        notCompeting: doc.get("notCompeting")
    }
}

export type GymnastRowEditMode = {
    isEditMode: boolean;
    gymnast: Gymnast;
}

export type GymnastRowReadMode = {
    isOpen: boolean;
    gymnast: Gymnast;
}