import firebase from "firebase";

export type Category = {
    id?: string,
    name?: string,
    apparatus?: string[],
    totalConfig?: TotalCalculationType
}

export enum TotalCalculationType {
    All = "ALL",
    OneBest = "ONE",
    TwoBest = "TWO",
    FreehandsPlusTwo = "FreehandsPlusTwo",
    //FreehandsPlusOne = "FhPlusOne"
}

export enum Apparatus {
    Freehands = "Frittstående",
    Rope = "Tau",
    Hoop = "Ring",
    Ball = "Ball",
    Clubs = "Køller",
    Vimpel = "Vimpel",
}

export function determineType(string: string): TotalCalculationType {
    switch (string) {
        case TotalCalculationType.All : return  TotalCalculationType.All;
        case TotalCalculationType.TwoBest: return TotalCalculationType.TwoBest;
        //case TotalCalculationType.FreehandsPlusOne: return TotalCalculationType.FreehandsPlusOne;
        case TotalCalculationType.FreehandsPlusTwo: return TotalCalculationType.FreehandsPlusTwo;
    }
    return TotalCalculationType.All;
}

export function categoryFromSnapshot(doc: firebase.firestore.DocumentSnapshot) {
    return {
        id: doc.id,
        name: doc.get("name"),
        apparatus: doc.get("apparatus") || [],
        totalConfig: determineType(doc.get("totalConfig")) || TotalCalculationType.All
    }
}