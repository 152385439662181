import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    addCompetition,
    deleteCompetition,
    getAllCompetitions,
    getAllCompetitionsForUser,
    updateCompetitionName, updateCompetitionVisibility
} from "../util/firebase";
import {Competition, CompetitionRow} from "../models/Competition";
import {UserContext} from "../util/auth";
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip} from "@material-ui/core";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from '@material-ui/icons/Delete';
import Visible from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './CompetitionList.css';
import '../index.css';
import strings from '../res/strings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

export interface CompetitionsCallback {
    (competitions: Competition[]): void
}

export interface UserIdProps {
    userId: string
}

export const CompetitionList = (): ReactElement => {
    const user = useContext(UserContext);

    return <div className="PaddedPage TextColored">
        {user ?
            <EditModeCompetitionList userId={user.uid}/> :
            <ReadOnlyCompetitionList/>}
    </div>

};

export const EditModeCompetitionList = ({userId}: UserIdProps): ReactElement => {
    const [rows, setRows] = useState<CompetitionRow[]>([]);
    const [adding, setAdding] = useState<boolean>(false);
    const [newCompetitionName, setNewCompetitionName] = useState<string>("");
    const history = useHistory();

    const setCompetitions = (competitions: Competition[]) => {
        setRows(competitions.map(c => {
            return {isEditMode: false, competition: c}
        }));
    };

    useEffect(() => {
        return getAllCompetitionsForUser(userId, setCompetitions);
    }, [userId]);

    let addCompetitionBlock = <div/>;
    if (adding) {
        addCompetitionBlock = <div>
            <FormControl style={{display: "inline-block"}}>
                <TextField id="outlined-basic" label={strings.competitionName} variant="outlined" size="small"
                           autoComplete="off"
                           onChange={event => {
                               setNewCompetitionName(event.target.value)
                           }}/>
                <Button variant="outlined" size="small" className="NewSaveCancelButton"
                        disabled={(newCompetitionName === "")} onClick={() => {
                    setAdding(false);
                    addCompetition(userId, newCompetitionName)
                }}>{strings.editing.save}
                </Button>
                <Button variant="outlined" size="small" className="NewSaveCancelButton" onClick={() => {
                    setAdding(false);
                }}>{strings.editing.cancel}
                </Button>
            </FormControl>
        </div>
    } else {
        addCompetitionBlock = <Button variant="outlined" onClick={() => setAdding(true)}
                                      startIcon={<AddCircleOutlineIcon/>}
                                      className="NewSaveCancelButton">{strings.addCompetition}</Button>
    }

    const onToggleEditMode = (id: string) => {
        setRows(() => {
            return rows.map(row => {
                if (row.competition.id === id) {
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };

    const onTogglePublishedComp = (id: string) => {
        setRows(() => {
            return rows.map(row => {
                if (row.competition.id === id) {
                    updateCompetitionVisibility({...row.competition, published: !row.competition.published})
                    return {competition: {...row.competition, published: !row.competition.published}, isEditMode: row.isEditMode};
                }
                return row;
            });
        });
    };

    return <div>
        <h1>{strings.myCompetitions}</h1>
        {addCompetitionBlock}
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.competition.id} hover={true}>
                            <TableCell className="EditCell">
                                {row.isEditMode ? (
                                    <>
                                        <Tooltip title={strings.editing.saveChanges}>
                                            <IconButton
                                                aria-label="done"
                                                onClick={() => {
                                                    onToggleEditMode((row.competition.id || ""));
                                                    updateCompetitionName(row.competition);
                                                }}
                                            >
                                                <DoneIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Tooltip title={strings.editing.edit}>
                                            <IconButton aria-label="edit"
                                                        onClick={() => {
                                                            onToggleEditMode((row.competition.id || ""))
                                                        }}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={strings.editing.delete}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    const confirmed = window.confirm(strings.confirmCompetitionDelete);
                                                    if (confirmed) {
                                                        deleteCompetition(userId, row.competition.id)
                                                    }
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title={row.competition.published ? strings.unpublish : strings.publish}>
                                            <IconButton
                                                aria-label="publishing"
                                                onClick={() => {
                                                    onTogglePublishedComp(row.competition.id)
                                                }}
                                            >
                                                {row.competition.published ? <Visible/> : <VisibilityOff/>}
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </TableCell>
                            {row.isEditMode ? (<TableCell><TextField variant="outlined" size="small"
                                                                     autoComplete="off"
                                                                     type="text"
                                                                     value={row.competition.name}
                                                                     onChange={e => {
                                                                         const newRows: CompetitionRow[] = rows.map(r => {
                                                                             if (r.competition.name === row.competition.name) {
                                                                                 r.competition.name = e.target.value;
                                                                             }
                                                                             return r;
                                                                         });
                                                                         setRows(newRows);
                                                                     }}
                            /></TableCell>) : (<TableCell
                                onClick={() => history.push(`/competition/${row.competition.id}`, row.competition.name)}>{row.competition.name}</TableCell>)

                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
};

export const ReadOnlyCompetitionList = (): ReactElement => {
    const [competitions, setCompetitions] = useState<Competition[]>([]);
    const history = useHistory();

    useEffect(() => {
        return getAllCompetitions(setCompetitions);
    }, []);

    return <div>
        <h1>{strings.competitions}</h1>
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {competitions.map((c) => (
                        <TableRow key={c.name} hover={true}>
                            <TableCell onClick={() => history.push(`/competition/${c.id}`, c.name)}>
                                {c.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
};