import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {CompetitionList} from "./competitionList/CompetitionList";
import {CategoryList} from "./categoryList/CategoryList";
import {ResultList} from "./resultList/ResultList";
import UserProvider from "./util/auth";
import {LoginView} from "./login/LoginView";
import {Toolbar} from "./Toolbar";

function App() {

    return (
        <UserProvider>
            <Router>
                <Toolbar/>
                <div>
                    <Switch>
                        <Route path="/competition/:competitionId/category/:categoryId">
                            <ResultList/>
                        </Route>
                        <Route path="/competition/:competitionId">
                            <CategoryList/>
                        </Route>
                        <Route path="/login">
                            <LoginView/>
                        </Route>
                        <Route path="/">
                            <CompetitionList/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;
