import React, {createContext, useEffect, useState} from "react";
import "firebase/auth";
import firebase, {User} from 'firebase/app';

export const UserContext = createContext<User | null>(null);

function UserProvider(props: React.PropsWithChildren<{}>) {
    const [user, setUser] = useState<User | null>(null);
    useEffect(() => {
            firebase.auth().onAuthStateChanged(user => {
                //console.log(user);
                setUser(user);
            });
        }
    );

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserProvider;


export async function login(username: string, password: string): Promise<string | null> {
    return firebase.auth().signInWithEmailAndPassword(username, password).then(() => null).catch((error: firebase.auth.Error) => {
        return error.message
    })
}

export function logout() {
    console.log("sign out");
    firebase.auth().signOut();
}