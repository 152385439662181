import React, {ReactElement, useContext, useEffect, useState} from "react";
import {
    addCategoryToCompetition,
    deleteCategory,
    getAllAvailableCategories,
    getAllCategoriesInCompetition,
    getCompetitionName
} from "../util/firebase";
import {useHistory, useParams} from "react-router-dom";
import {Category, determineType, TotalCalculationType} from "../models/Category";
import {UserContext} from "../util/auth";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip
} from "@material-ui/core";
// Icons
import DeleteIcon from '@material-ui/icons/Delete';
import '../index.css';
import strings from '../res/strings';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";

export interface CategoriesCallback {
    (categories: Category[]): void
}

export interface CompNameCallback {
    (competitionName: string): void
}

export const CategoryList = (): ReactElement => {
    const user = useContext(UserContext);

    return <div className="PaddedPage TextColored">
        {user ?
            <EditModeCategoryList/> :
            <ReadOnlyCategoryList/>}
    </div>

};

export const EditModeCategoryList = (): ReactElement => {
    let {competitionId} = useParams();
    const [rows, setRows] = useState<Category[]>([]);
    const [competitionName, setCompetitionName] = useState<string>("");
    const [adding, setAdding] = useState<boolean>(false);
    const [addPredefinedCategoryActive, setAddPredefinedCategoryActive] = useState<boolean>(false);
    const [addOwnCategoryActive, setaddOwnCategoryActive] = useState<boolean>(false);
    const [availableCategories, setAvailableCategories] = useState<Category[]>([]);
    const [newCategory, setNewCategory] = useState<Category>({});
    const history = useHistory();
    const [apparatus, setApparatus] = useState<string[]>([]);
    const [customApparatus, setCustomApparatus] = useState<string>("");

    useEffect(() => {
        return getAllCategoriesInCompetition(competitionId, (categories: Category[]) => {
            setRows(categories);
        });
    }, [competitionId]);

    useEffect(() => {
        return getAllAvailableCategories(competitionId, (categories: Category[]) => {
            setAvailableCategories(categories);
        });
    }, [competitionId]);

    useEffect(() => {
        return getCompetitionName(competitionId, (competitionName: string) => {
            setCompetitionName(competitionName);
        });
    }, [competitionId]);

    useEffect(() => {
        return setApparatus(Array.from(new Set(availableCategories.flatMap(c => {
            return c.apparatus ? c.apparatus : []
        }))))
    }, [availableCategories]);

    const addOwnCategoryBlock = <div style={{display: "grid"}}>
        <FormControl disabled={addPredefinedCategoryActive} style={{paddingBottom: "10px"}}>
            <InputLabel htmlFor="outlined-age-native-simple">{strings.categoryName}</InputLabel>
            <Input autoComplete="off" onChange={event => {
                setaddOwnCategoryActive(event.target.value !== "");
                setNewCategory({...newCategory, name: event.target.value});
            }}/>
        </FormControl>
        <FormControl disabled={addPredefinedCategoryActive} style={{paddingBottom: "10px"}}>
            <FormLabel component="legend">{strings.chooseApparatus}</FormLabel>
            <FormGroup style={{display: "block"}}>
                {apparatus.map(a =>
                    <FormControlLabel style={{width: "25%"}}
                                      control={<Checkbox onChange={() => {
                                          let ca: string[] = newCategory.apparatus || [];
                                          if (ca.includes(a)) {
                                              ca = ca.filter((v) => v !== a);
                                              console.log(ca);
                                          } else {
                                              ca.push(a);
                                          }
                                          setaddOwnCategoryActive(ca.length !== 0);
                                          setNewCategory({...newCategory, apparatus: ca});
                                      }} color="primary"/>}
                                      label={a}
                    />)
                }
                <FormControl disabled={addPredefinedCategoryActive}
                             style={{paddingBottom: "10px", display: "inline-block"}}>
                    <Input autoComplete="off" id="customApparatusInput" placeholder={strings.customApparatus} onChange={event => {
                        setaddOwnCategoryActive(event.target.value !== "");
                        setCustomApparatus(event.target.value);
                    }}/>
                    <IconButton disabled={customApparatus === ""}
                                onClick={() => {
                                    var customApparatusInput = document.getElementById("customApparatusInput") as HTMLInputElement;
                                    if (customApparatusInput) customApparatusInput.value = "";
                                    setApparatus(apparatus.concat(customApparatus));
                                }}>
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </FormControl>
            </FormGroup>
        </FormControl>
        <FormControl disabled={addPredefinedCategoryActive}>
            <InputLabel htmlFor="outlined-age-native-simple">{strings.chooseTotalConfig}</InputLabel>
            <Select native
                    defaultValue="None" name="total" onChange={event => {
                setaddOwnCategoryActive(event.target.value !== "None");
                setNewCategory({
                    ...newCategory,
                    totalConfig: determineType(event.target.value as string)
                });
            }}>
                <option value="None"/>
                {
                    Object.values(TotalCalculationType).map(v =>
                        <option key={v} value={v}>{strings.totalConfigs[v]}</option>
                    )
                }
            </Select>
        </FormControl>
    </div>;

    const chooseCategory = <div><FormControl disabled={addOwnCategoryActive}
                                             style={{width: "120px", verticalAlign: "bottom", display: "inline"}}>
        <InputLabel htmlFor="outlined-age-native-simple">{strings.chooseCategory}</InputLabel>
        <Select
            native
            defaultValue="None"
            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                setAddPredefinedCategoryActive(event.target.value !== "None");
                const category: Category = JSON.parse(event.target.value as string);
                setNewCategory(category)
            }}
            label={strings.chooseCategory}
        >
            <option value="None"/>
            {availableCategories.map(c =>
                <option value={JSON.stringify(c)}>{c.name}</option>
            )}
        </Select>
    </FormControl></div>;

    let addCategoryBlock = <div/>;
    if (adding) {
        addCategoryBlock = <div>
            <h3>{strings.choosePredefinedCategory}</h3>
            <div>
                {chooseCategory}
            </div>
            <h3>{strings.addOwnCategory}</h3>
            <div>
                {addOwnCategoryBlock}
            </div>
            <Button variant="outlined" size="small"
                    disabled={!(newCategory.apparatus && newCategory.apparatus.length !== 0 && newCategory.name && newCategory.name !== "" && newCategory.totalConfig)}
                    onClick={() => {
                        setAdding(false);
                        addCategoryToCompetition(competitionId, newCategory);
                        setAddPredefinedCategoryActive(false);
                        setaddOwnCategoryActive(false);
                    }}>{strings.editing.save}
            </Button>
            <Button variant="outlined" size="small" onClick={() => {
                setAdding(false);
                setAddPredefinedCategoryActive(false);
                setaddOwnCategoryActive(false);
            }}>{strings.editing.cancel}
            </Button>
        </div>
    } else {
        addCategoryBlock = <Button variant="outlined" onClick={() => setAdding(true)}
                                   startIcon={<AddCircleOutlineIcon/>}>{strings.addCategory}</Button>
    }

    return <div>
        <h1>{competitionName}</h1>
        {addCategoryBlock}
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className="EditCell">
                                <Tooltip title={strings.editing.delete}>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            const confirmed = window.confirm(strings.confirmCategoryDelete);
                                            if (confirmed) {
                                                deleteCategory(competitionId, row.id || "")
                                            }
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onClick={() => history.push(`/competition/${competitionId}/category/${row.id}`, row)}>
                                {row.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
};

export const ReadOnlyCategoryList = (): ReactElement => {
    let {competitionId} = useParams();
    const [categories, setCategories] = useState<Category[]>([]);
    const [competitionName, setCompetitionName] = useState<string>("");
    const history = useHistory();

    useEffect(() => {
        return getAllCategoriesInCompetition(competitionId, setCategories);
    }, [competitionId]);

    useEffect(() => {
        return getCompetitionName(competitionId, setCompetitionName);
    }, [competitionId]);

    return <div>
        <h1>{competitionName}</h1>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {categories.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell
                                onClick={() => history.push(`/competition/${competitionId}/category/${row.id}`, row)}>
                                {row.name}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
};
